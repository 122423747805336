























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class InsFooterSwiper extends Vue {
  initOptions!: object;
  initSwiper: boolean = true;
  page: string = 'Sponsor';
  data!: any;
  currentYear: number = 0;
  tabIndex: number = 1;
  showSub: boolean = false;
  sponsorList: object[] = [];
  show: boolean = true;

  swiperOption: object = {
    autoplay: {
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.f-swiper-button-next',
      prevEl: '.f-swiper-button-prev'
    },
    autoHeight: true, // 高度随内容变化
    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
    observeParents: true, // 修改swiper的父元素时，自动初始化swiper
    slidesPerView: 2,
    spaceBetween: 10
  };

  toggleTab (index) {
    this.tabIndex = index;
  }

  created () {
    var date = new Date();
    this.currentYear = date.getFullYear();
  }

  getSponsorList () {
    this.$Api.promotion
      .GetPromotionByDevice({
        Page: 'Sponsor',
        Position: 2,
        IsMobile: this.isMobile,
        BannerEnable: true
      })
      .then(result => {
        this.sponsorList = result.Promotion.BannerList;
        console.log(this.sponsorList, 'footer合作伙伴数据');
      })
      .catch(error => {
        console.log(error, 'error');
      });
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  mounted () {
    if (this.page) {
      this.getSponsorList();
    } else {
      if (this.initSwiper) {
        this.sponsorList = this.data;
      } else {
        if (typeof this.data === 'object') {
          //   this.manImg = this.data[0].Image;
        } else if (typeof this.data === 'string') {
          //   this.manImg = this.data;
        }
      }
    }
  }

  @Watch('isMobile', { deep: true })
  onMediaChange () {
    if (this.initSwiper) {
      this.sponsorList = [];
      this.getSponsorList();
    }
  }
}
